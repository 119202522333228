import React from "react"
import { Link } from "gatsby"
import { FaUserPlus, FaFile, FaFolderOpen } from "react-icons/fa"

import { FacebookProvider, Page } from "react-facebook"
import { YoutubePlayer } from "reactjs-media"

import Layout from "../components/layout"
import SEO from "../components/seo"
import bg from "../images/image8-1.jpg"

// import vaasa1 from "../images/vaasa_2022/vaasa_1.jpg"
// import vaasa2 from "../images/vaasa_2022/vaasa_2.jpg"
// import vaasa3 from "../images/vaasa_2022/vaasa_3.jpg"
// import vaasa_raigo from "../images/vaasa_2022/vaasa_raigo.jpg"

// import joulu from "../images/hyvaa_joulua.jpg"

import espoo1 from "../images/espoo_2024/espoo_bandi.jpg"
import espoo2 from "../images/espoo_2024/espoo_kirkkohallitus.jpg"
import espoo3 from "../images/espoo_2024/espoo_ollila.jpg"


// import vanaja3 from "../images/vanajanlinna3.jpg"

const IndexPage = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Home" />
    <div className="row">
      <div className="spacer-50" />
      <div
        className="col-md-4 text-smaller"
        style={{ borderRight: "thin solid #D3D3D3" }}
      >
        <h2 className="no-margin-top">
          Pääsihteerin <strong className="accent-color">palsta</strong>
        </h2>
        <p>
          <i>
            <b>
              Suomen hautaustoiminnan keskusliitto ry toimii alan yhteisöjen
              keskusjärjestönä Suomessa. kehitämme hautaustoimintaa, edistämme
              alalla toimivien yhteistyötä ja vaalimme sekä edistämme
              hautauskulttuuria
            </b>
          </i>
        </p>
        <p>
          Tästä voit ladata{" "}
          <a href="/doc/valtakunnalliset_ohjeet_krematorion_yllapitajalle_tuhkanluovutuksessa.docx">
            Valtakunnalliset ohjeet krematorion ylläpitäjälle
            tuhkanluovutuksessa
          </a>
        </p>
        {/* <p>
          Seuraavat koulutuspäivät järjestämme Rovaniemellä marraskuun
          7.-8.11.2023;
          <br />
          uusi krematoriopaikkakunta tarjoaa kiinnostavat puitteet päivien
          järjestelyille.
        </p> */}
        <p>
          Vuoden 2025 koulutuspäivät järjestetään Porvoossa  18.-19.3.2025  ja  Joensuussa   4.-5.11.2025.</p>
        <p>Varaathan ajankohdat koulutuskalenteriisi!</p>
        {/* <a
            href="https://www.facebook.com/Suomen-Hautaustoiminnan-Keskusliitto-Ry-107307047294215/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Siirry facebookiin.
          </a> */}
        <h3>Ajankohtaista Facebookista</h3>
        <FacebookProvider appId="338831073661381">
          <Page
            href="https://www.facebook.com/Suomen-Hautaustoiminnan-Keskusliitto-Ry-107307047294215"
            tabs="timeline"
            width={360}
            height={700}
            adaptContainerWidth
          />
        </FacebookProvider>
      </div>
      <div className="col-md-8">
        <h1>Tervehdys Suomen hautaustoiminnan keskusliitosta</h1>
        <h2>Marraskuun koulutuspäivät lähestyvät</h2>
        <p>
          Liittomme koulutuspäivät on päätetty järjestää marraskuussa Kokkolassa. Original Sokos Hotel Karelia, Kauppatori 4 KOKKOLA. Yhdistyksemme hallitus on valmistellut Kokkolan koulutuspäivien ohjelman, joka tämänkin kertaisella ohjelmallaan pureutuu asiantuntijoiden voimin moniin ajassa liikkuviin alan kysymyksiin.
        </p>
        <p>
          Yhdistyksemme katsotaan perustetun vuonna 1889 Suomen Kuolleenpolttoyhdistyksen nimellä (Likbränningsföreningen i Finland). Maassamme virisi tuolloin kiinnostus polttohautausta kohtaan ja näin joukko aatteellisesti virittäytyneitä henkilöitä kokoontuivat polttohautausyhdistyksen perustamistilaisuuteen. Tuosta hetkestä alkoi pitkä ja vaivalloinen kamppailu polttohautauksen laillistamiseksi. Tällä hetkellä tuhkaus on hautausmuotona ohittanut arkkuhautauksen.
        </p>

        <p>
          Iltaohjelmassa on Kahakka-ruokailu, jossa tehdään aikamatka Krimin sodan aikaiseen kahakkaan ja jossa ruokailu toteutetaan ajan hengen mukaisesti!
        </p>
        <p>
          Ohjelma ja ilmoittautumiskanava löytyy linkistä - <Link to="/sahkoinen-ilmoittautuminen-koulutuspaiville">sähköinen ilmoittautuminen</Link>
        </p>
        <p>
          Toivottavasti Sinä ehdit jakaa koulutuspäiväinfoa työyhteisössäsi ja myötävaikutat näin päivien onnistumiseen runsain joukoin!
        </p>
        <p>
          Matti Halme<br />
          pääsihteeri
        </p>



        {/*<h1>Koulutuspäivät Rovaniemellä - sähköinen ilmoittautuminen </h1>
         <h2>Ajankohtaista hautausalan ammattilaisille</h2>
        <p className="font-bold">
          Aika: 7.-8.11.2023
          <br />
          Hotelli Santa Claus, Korkalonkatu 29 Rovaniemi
        </p>

        <h2>Ohjelma</h2>
        <ul className="list-none">
          <li className="font-bold">Tiistai 7.11.2023</li>
          <li>8.30-10.00 Suolainen aamupala kahvin tai teen kera ja ilmoittautumien</li>
          <li>
            10.00 Päivien avaus. Puheenjohtaja Mikael Wilén - SHK RY
          </li>
          <li>
            10.15-10.45	Rovaniemen seurakunnan tervehdys ja esittely, <br /> Talousjohtaja Antti Jääskeläinen ja hautaustoimen päällikkö Miia Ylipulli

          </li>
          <li>
            10.45-11.00	Some työkaluna asiakasviestinnässä,
            <br />
            viestintäpäällikkö Noora Hietanen, Rovaniemen seurakunta
          </li>
          <li>
            11.00-12.00	Aivohyvinvointi - oman jaksamisen tukena <br />
            työterveyspsykologi Mia Valtonen, Terveystalo Oy
          </li>
          <li>12.00-13.00 Lounas</li>
          <li>
            13.00	Hautaustoimistojen eettiset- ja kauppatapaohjeet<br />
            johtaja Veli-Pekka Jokela, Jokelan Hautaustoimisto Rovaniemi

          </li>
          <li>14.00 Kahvi</li>
          <li>
            14.30-15.45 Harrin klinikka <br />
            maankäyttöpäällikkö Harri Palo, kirkkohallitus<br />
            Harri Palon ajankohtaiskatsaus ja vastaukset hänelle etukäteen osoitettuihin kysymyksiin
          </li>
          <li>19.00 Iltaruokailu ja ohjelma</li>
        </ul>
        <ul className="list-none">
          <li className="font-bold">Keskiviikko 8.11.2023</li>
          <li>
            9.15 Lähtö bussilla Tavivaaran hautausmaalle, jossa tutustuminen uuteen Rovaniemen krematorioon, <br />
            asiantuntija Markus Forsström, Höganäs Borgestad Oy
          </li>
          <li>
            11.15-12.00	Hautausmaan hoitosuunnitelma käytännössä<br />
            Ohjeita hoitosuunnitelman laadintaan

          </li>
          <li>12.00 Lounas</li>
          <li>
            13.00 Energiasäästöä älykkäällä valaistuksella<br />
            Varatoimitusjohtaja Petri Laitinen, C2 Smart Light
          </li>

          <li>13.30	Sähköinen krematoriouuni<br />
            Teemu Kanerva - Dust Control Systems Oy
          </li>
          <li>
            14.30	Päätöskahvit
          </li>

        </ul>
        <p>
          Ohjelma, lisätiedot ja ilmoittautumiskanava löytyy linkistä -{" "}
          <a href="/sahkoinen-ilmoittautuminen-koulutuspaiville">
            sähköinen ilmoittautuminen
          </a>
        </p> */}
        {/* <p>
          Hotellihuoneiden hinnat ovat 124,-/vrk/ yhden hengen huone ja
          139,-/vrk kahden huone; Prime Guest luokan huoneet 134,- ja 149,-.
          Huonevaraukset tulee tehdä itse 21.03.2023 mennessä. Jokainen varaa
          huoneensa Hotels -sivuston kautta{" "}
          <a href="https://www.hotelsveitsi.fi" target="_blank">
            www.hotelsveitsi.fi
          </a>
          . Varauskoodi on <b>SHK2023</b>. Varaus tulee joko taata
          luottokortilla tai maksaa ennakkoon. Tiistaiaamuna saapuvalle
          kurssilaiselle kuuluu hotelliaamiainen!
        </p>
        <p>
          <b>Liittokokous järjestetään Hyvinkäällä 28.03.2023 klo 16.00</b>.
          Kokouskutsu liitteineen lähetetään myöhemmin erikseen. Toivottavasti
          Sinä ehdit jakaa koulutuspäiväinfoa työyhteisössäsi ja myötävaikutat
          näin päivien onnistumiseen runsain joukoin!
        </p> */}
        {/* <h2>
          Suomen Hautaustoiminnan Keskusliitto toivottaa kaikille rauhallista ja
          siunattua joulua, sekä hauskaa uutta vuotta! <br />
          <br />
          Haluamme kiittää kuluneesta vuodesta kumppaneitamme yhteistyöstä.
        </h2> */}
        {/* <img
          className=""
          style={{ width: "500px", marginBottom: "50px" }}
          src={joulu}
          alt=""
        /> */}
        {/* <h2>Koulutuspäivät Vaasassa</h2>
       
        <img
          className="md:float-right"
          style={{ width: "300px", marginLeft: "30px" }}
          src={vaasa1}
          alt=""
        />
         
        <p>
        Vaasan koulutuspäivät olivat hyvin onnistuneet ja ajankohtaiset. Siitä varmasti osaltaan viestii runsas osanottajajoukko, yli 70 kuulijaa saapui kuulemaan mm varautumisesta ja huoltovarmuudesta.</p><p>
Kiitämme Vaasan seurakuntayhtymän hautaustoimea hyvästä isännyydestä ja lämpimästä vastaanotosta. Vaasassa on hautatoimi hoidettu esimerkillisellä tavalla ja myös henkilökunta antoi osaavan kuvan.</p>
<p>Ensi kevään maaliskuussa tapaamme Hyvinkäällä, jossa esillä ovat mm. hautaustoimen uudesta Kuoleman ekosysteemi -projektista ja pienimuotoinen näyttely hautausalan tarvikkeista on esillä.  Hyvinkään Sveitsissä pyrimme järjestämään myös kurssilaisille aikaa hyvinvointiin, onhan hotellin yhteydessä kattavat virkistyspalvelut uimahalleineen.</p>
<img
          className="md:float-left"
          style={{ width: "300px", marginRight: "30px" }}
          src={vaasa3}
          alt=""
        />
<p>
Tässä vielä Harri Palon lähettämä linkki krematoriokeskusteluun:<br />
<a href="https://teams.microsoft.com/l/channel/19%3a1f32c57162574eeeb1412ff0564b346e%40thread.skype/Yleinen?groupId=a48c8e33-d303-432c-82ec-44d22d6f1964&tenantId=a609c794-a48e-43b2-be34-990f3b068db2" target="_blank">Katso linkki</a>

</p>
<p>Kanavalle liittyminen edellyttää, että käyttäjäksi haluava klikkaa osallistumispyynnön kanavan omistajalle. Heitä on useampia, joten vastaus tulee yleensä nopeasti.
</p>

 
<p>
Kiitokset taas menneestä vuodesta ja oikein hyvää loppuvuotta!
</p>

<p>

<a href="/aiemmat-luentomateriaalit">Aiemmat luentomateriaalit </a>- osiosta löydät Vaasan päivien luentomateriaalin.
<div className="spacer-60"></div>

<img
          className="md:float-left"
          style={{ width: "750px" }}
          src={vaasa2}
          alt=""
        />

        </p>
        
        
        <div className="spacer-60"></div> */}

        {/* <h2>KOULUTUSPÄIVÄT SYKSYLLÄ VAASASSA</h2>

        <p>
          Seuraavat koulutuspäivät ovat Vaasassa 8.-9.11.22. Saamme tutustua
          Vaasan krematoriotoimintaan ja saamme tietää hautausalan viimeisimmät
          alan kuulemiset.
        </p>

        <p>
          Vuoden 2023 koulutuspäivät ovat Hyvinkäällä 21.-22.3.23 ja
          Rovaniemellä 7.-8.11.23.
        </p>

        <h2>Ennakoiden ja varautuen huomiseen</h2>
        <p className="font-bold">
          Aika: 08.-09.11.2022
          <br />
          Paikka: Hotelli Scandic Waskia, Lemmenpolku 3 Vaasa
        </p>

        <h2>Ohjelma</h2>
        <ul className="list-none">
          <li className="font-bold">Tiistai 08.11.2022</li>
          <li>8.30-9.30 Aamupala ja ilmoittautumien</li>
          <li>9.30 Päivien avaus. Puheenjohtaja Mikael Wilén - SHK RY</li>
          <li>
            9.45-10.15 Vaasan seurakuntayhtymän tervehdys ja esittely <br />
            Hautaustoimen päällikkö Ralf Lillfors
          </li>
          <li>
            10.15-11.35 Miten vastaanotamme ukrainan pakolaiset <br />
            seurakuntapastori Raigo Liiman - Espoon tuomiokirkkoseurakunta
          </li>
          <li>
            11.45-12.30 Huoltovarmuuskysymykset seurakunnissa <br />
            ylitarkastaja Taru Ruutiainen- Länsi- ja Sisä-Suomen
            Aluehallintovirasto
          </li>
          <li>12.30 Lounas</li>
          <li>
            13.30 Hautaustoimen säädökset ja niiden soveltaminen - opinnäytetyön
            esittely <br />
            seurakuntapuutarhuri Petri Solja - Lempäälän seurakunta
          </li>
          <li>14.30 Kahvi</li>
          <li>
            15.00-16.00 Katrina - Kyrkogård hautakirjajärjestelmä <br />
            projektipäällikkö Hanna Kuirinlahti - Vitec Katrina Oy
          </li>
          <li>19.00 Iltaruokailu ja ohjelma</li>
        </ul>
        <ul className="list-none">
          <li className="font-bold">Keskiviikko 09.11.2022</li>
          <li>
            9.00 Seurakunnan Valmiussuunnittelu <br />
            rovasti, asiantuntija Jukka Helin - Lapuan hiippakunta
          </li>
          <li>
            10.00 Tutustuminen Vaasan seurakuntayhtymän krematorioon ja
            hautausmaakierros
          </li>
          <li>
            11.30 Uuden jätelain ja -asetuksen tuomat muutokset <br />
            Vaasan kaupunki - ympäristöosasto
          </li>
          <li>12.30 Lounas</li>
          <li>
            13.30 Harrin klinikka <br />
            maankäyttöpäällikkö Harri Palo - kirkkohallitus <br />
            Harri Palon ajankohtaiskatsaus ja vastaukset hänelle etukäteen
            osoitettuihin kysymyksiin
          </li>

          <li>14.30 Päivien päätös ja kahvit</li>
        </ul>
        <div className="spacer-60"></div>
        <p>
          <Link
            className="button button-primary inline-block"
            to="/sahkoinen-ilmoittautuminen-koulutuspaiville"
          >
            Ohjelma ja sähköinen ilmoittautuminen koulutuspäiville
          </Link>
        </p> */}
        {/* <h2>Osallistumismaksut ja majoitus</h2>
            <p className="font-bold">
              Kouvolan 30.-31.3.2022 koulutuspäivien osallistumismaksut:
            </p>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden">Koulutuspaketti ke-to</div>
              <div class="w-1/3 overflow-hidden">yhdistyksen jäsen </div>
              <div class="w-1/3 overflow-hidden">290 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">ei jäsen</div>
              <div class="w-1/3 overflow-hidden">340 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden mt-3">
              <div class="w-1/3 overflow-hidden">Yksi päivä</div>
              <div class="w-1/3 overflow-hidden">yhdistyksen jäsen </div>
              <div class="w-1/3 overflow-hidden">180 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">ei jäsen</div>
              <div class="w-1/3 overflow-hidden">230 eur</div>
            </div>
            <p>
              Hintoihin sisältyy koulutus, ruokailut sekä keskiviikon
              iltaohjelma. Myös keskiviikkoaamuna saapuville on varattu hotellin
              buffet-aamiainen
            </p> */}
        {/* <p>
              Hotelli Scandic Lahti City, Kauppakatu 10 LAHTI, varaustunnus{" "}
              <b>SUO 1003</b> (varaus soittamalla) puh. 09 42722 504
            </p>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden">Huonehinnat</div>
              <div class="w-1/3 overflow-hidden">1 hengen huone</div>
              <div class="w-1/3 overflow-hidden">105 eur/yö</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">2 hengen huone</div>
              <div class="w-1/3 overflow-hidden">125 eur/yö</div>
            </div>
            <br />
            <p>
              Jokainen varaa huoneensa{" "}
              <a target="_blank" href="https://www.sokoshotels.fi/fi/kouvola">
                Sokos Hotels
              </a>{" "}
              -sivuston kautta 15.03.2022 mennessä! Varauskoodi on BSHK2022
            </p>
            <p>
              Varaus tulee joko taata luottokortilla tai maksaa ennakkoon.
              Varauksen voi myös tehdä suoraan hotellista puhelimitse{" "}
              <b>+358 10 7839101</b> tai sähköpostilla{" "}
              <a href="mailto: reception.kouvola@sokoshotels.fi">
                reception.kouvola@sokoshotels.fi
              </a>
              . Varausvaiheessa tulee mainita kiintiötunnus{" "}
              <b>"SHK liittokokous ja koulutuspäivät"</b>.
            </p>

{/* <p>
          Jäsenmaksuksi vuodelle 2023 vahvistettiin:
        </p>
        <table className="pure-table">
          <thead>
            <tr>
              <th>Seurakunnan/seurakuntayhtymän jäsenmäärä</th>
              <th>Jäsenmaksu</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>alle 10&nbsp;000 asukasta</td>
              <td>150 euroa</td>
            </tr>
            <tr>
              <td>10&nbsp;000 – 50&nbsp;000 asukasta</td>
              <td>300 euroa</td>
            </tr>
            <tr>
              <td>50&nbsp;001 – 100&nbsp;000 asukasta</td>
              <td>500 euroa</td>
            </tr>
            <tr>
              <td>100&nbsp;001 – 200&nbsp;000 asukasta</td>
              <td>800 euroa</td>
            </tr>
            <tr>
              <td>yli 200&nbsp;000 asukasta</td>
              <td>1400 euroa</td>
            </tr>
          </tbody>
        </table>
        <div className="spacer-35"></div> */}
        {/* <img
          className="md:float-right"
          style={{ width: "300px", marginRight: "30px" }}
          src={luento}
          alt=""
        /> */}

        {/* <p>Ohjelma ja ilmoittautumiskanava löytyy linkistä - <a href="/sahkoinen-ilmoittautuminen-koulutuspaiville">sähköinen ilmoittautuminen</a></p>

<p>Nyt joukolla mukaan kokemaan ammattiveljien ja -siskojen yhteisöllisyyttä sekä saamaan työkaluja arkielämän jaksamiseen.</p>

<p><b>TERVETULOA</b></p> */}

        {/*
        <p>
          Suomen Hautaustoimistojen Liitto ry on useana vuonna valinnut Vuoden
          seurakunnan tai hautausmaan. Huomionosoituksemme ja palkinnon
          myöntämisen perusteena on asiakkaiden ja hautaustoimistojen antama
          palaute ja kokemukset palvelusta sekä yhteistyöstä. Palkinto annetaan
          seurakunnalle tai hautausmaalle, jonka henkilöstö tukee omaisia ja
          suhtautuu heihin erityisen myötäeläen, ystävällisesti ja huomioiden
          heidän tarpeensa.
        </p>
        <img
          className="md:float-left"
          style={{ width: "300px", marginRight: "30px" }}
          src={vanaja3}
          alt=""
        />
        <p>
          Palkitsemisen perusteena on myös hyvä ja rakentava sekä kehittävä,
          joustava yhteistyö seurakunnan ja hautaustoimistojen kesken. Suomen
          Hautaustoimistojen Liitolle on tärkeää, että seurakuntien ja
          hautaustoimistojen yhteistyö on mutkatonta ja toimimme molemmat
          omaisten parhaaksi. Hautausjärjestelyissä on aina lähtökohtana
          omaisten toiveet, jota kumpikin taho pyrkii toteuttamaan parhaansa
          mukaan ja näin yhteistyö tässä on erittäin merkittävä asia.
        </p>
        <img
          className="md:float-right"
          style={{ width: "200px", marginLeft: "30px" }}
          src={vanaja1}
          alt=""
        />evel
        <p>
          Suomen Hautaustoimistojen Liitto on valinnut edellä mainittujen
          seikkojen perusteella vuoden 2020 seurakunnaksi Kempeleen seurakunnan,
          joka palkittiin Hämeenlinnan päivillä. Seurakunnan puolesta palkinnon
          vastaanotti kirkkoherra Kimmo Helomaa.
        </p> */}
        {/* <p>
          Kotisivuiltamme voit lukea Lahden päivien{" "}
          <a href="/aiemmat-luentomateriaalit">luentomateriaaleja</a>. Syksyllä
          tapaamme Vaasassa 3.-4.11.2020. Tervetuloa!
        </p> */}
        {/* <div className="spacer-60"></div> */}

        <h2>Ohessa linkit yhteistyökumppaniemme sivuille</h2>
        <p>
          <a href="https://eperusteet.opintopolku.fi/eperusteet-service/api/dokumentit/4579831">
            Opetushallitus - Seurakunta- ja hautauspalvelualan ammattitutkinnon
            perusteet, PDF
          </a>
        </p>
        <p>
          <a href="https://kivi.info/">Kivi Ry</a>
        </p>
        <p>
          <a
            href="https://www.hautahaku.fi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hautahaku
          </a>
        </p>
        <p>
          <a
            href="https://thl.fi/fi/palvelut-ja-asiointi/valtion-sosiaali-ja-terveydenhuollon-erityispalvelut/oikeuslaakinta/ajankohtaista/vainajien-kylmasailytys-ja-vainajaprosessit-seminaari-20.10.2020"
            target="_blank"
            rel="noopener noreferrer"
          >
            Vainajien kylmäsäilytys ja vainajaprosessit -seminaari 20.10.2020
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cremation.org.uk"
          >
            The Cremation Society{" "}
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://evl.fi/plus/etusivu"
          >
            Evl.fi/plus{" "}
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.seurakuntapuutarhurit.fi/"
          >
            Seurakuntapuutarhurit{" "}
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hautajaiset.fi/"
          >
            Suomen Hautaustoimistojen Liitto Ry{" "}
          </a>
        </p>
        {/* <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.absentus.com/fi/muistomerkki-voi-olla-laheisesta-kertova-taideteos"
          >
            Absentus - Muistomerkki voi olla läheisestä kertova taideteos{" "}
          </a>
        </p> */}
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://uutiskirje.sht-tukku.fi/a/s/140885812-32163aea67ca4e662dca332dd4c76c84/3171173"
          >
            SHT Tukku Oy - Hyvät hautajaiset{" "}
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://absentus.com/"
          >
            Absentus Oy
          </a>
        </p>
        {/* <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://shk.fi/doc/absentus-uudet-materiaalit-tiimalasi-1-2019.pdf"
          >
            Absentus - Tiimalasi - Uusien materiaalien käyttö
            hautamuistomerkeissä
          </a>
        </p> */}
        {/* <p>
          Kotisivuiltamme voit lukea Vantaan päivien luentomateriaaleja.
          Keväällä tapaamme Lahdessa 10.-11-3.2020. Tervetuloa
        </p>

        <p>
          Yhdistyksemme 130-vuotistaivalta muistellaan Vantaalla. Vielä ehtii
          koulutuspäiville 5.-6.11.19.
        </p>
        <p>
          Katso pääsihteeri Matti Karjalaisen{" "}
          <a href="doc/esitys_4.11.2014_hameenlinna.pptx"> esitys</a> vuodelta
          2014 Hämeenlinnan juhlahetkessä.
          Kaunis kesäaika alkaa lähentyä syyspuolelle. Toimelias aikakausi
          hautausmailla on kohta takanapäin ja syystöiden aika on edessä.
          Edessäpäin on myös syksyn koulutuspäivät Vantaalla. Suunnitelman
          mukaisesti kokoonnumme Hotelli Vantaan tiloihin 5.-6.11.19 ammentamaan
          ajankohtaista ja tarvittavaa tietotaitoa.
        </p>
        <p>
          Yhdistyksemme keskeisiä tehtäviä on kehittää hautaustoimintaa, edistää
          alalla toimivien yhteistyötä ja vaalia ja edistää hautauskulttuuria.
          Näin kahdesti vuodessa järjestettävät koulutuspäivät palvelevat
          osaltaan näiden tehtäväalueiden toteuttamista. Vuoden 2020
          koulutuspaikat ja ajankohdat ovat seuraavat: kevään 2020 liittokokous-
          ja koulutuspaikkakunta on Lahti. Koulutus- ja liittokokousajankohta
          10.-11.03.2020. Syksyn koulutuspäivät ovat Vaasassa 3.- 4.11.2020.
        </p>
        <p>
          Syksyn Vantaan päivillä on myös yhdistyksemme pitkä historia esillä.
          Vuonna 1889 perustettu yhdistyksemme ensimmäinen nimi oli Suomen
          kuolleenpolttoyhdistys. Laillinen järjestäytyminen tapahtui Suomen
          Ruumiinpolttoyhdistyksen nimellä vuonna 1907. Vuonna 1924
          yhdistyksemme kirjattiin yhdistysrekisteriin ja seuraavana vuonna
          nimeksi muutettiin Suomen Krematorioyhdistys. Tähän päivään ja hetkeen
          on kuljettu pitkä ja monivaiheinen matka. Sitä muistellaan ja
          kunnioitetaan Vantaan tiistain iltapäivän aikana.
        </p>
        <p>
          Vantaan päivät tarjoavat siis erityisen lisävivahteen ja näin on
          erityisen kiinnostavaa edessä. Kannattaa ilmoittautua mukaan.{" "}
        </p>
        <p>TERVETULOA!</p>
        <p>
          <Link
            className="button button-primary inline-block"
            to="/sahkoinen-ilmoittautuminen-koulutuspaiville"
          >
            Ohjelma ja sähköinen ilmoittautuminen koulutuspäiville
          </Link>
        </p> */}

        <div className="spacer-45" />
      </div>
    </div>
    <div className="spacer-45" />
    <div
      className="banner parallax parallax2 full-container"
      style={{ backgroundImage: `url(${bg})`, backgroundPosition: "50% 40px" }}
    >
      <div className="accent-overlay" />
      <div className="banner-caption text-center">
        <h2 className="text-white text-xxl sm:text-auto">
          Seuraavat koulutuspäivät Kokkolassa 5.-6.11.24. Tervetuloa!
        </h2>
      </div>
    </div>
    <div className="flex flex-wrap mt-6 mb-6">
      <div className="text-center md:w-4/12">
        <div className="icon-box ibox-center ibox-secondary ibox-outline ibox-effect ibox-plain">
          <div className="ibox-icon text-yellow text-6xl text-center">
            <FaUserPlus className="inline-block" />
          </div>
          <h3>Ilmoittaudu koulutuspäiville</h3>
          <p className="text-sm">
            Ilmoittautuminen onnistuu helposti sivuillamme
          </p>
          <p>
            <Link
              className=" button button-primary inline-block"
              to="/sahkoinen-ilmoittautuminen-koulutuspaiville"
            >
              Sähköinen ilmoittautuminen
            </Link>
          </p>
        </div>
      </div>
      <div className="text-center md:w-4/12">
        <div className="icon-box ibox-center ibox-secondary ibox-outline ibox-effect ibox-plain">
          <div className="ibox-icon text-yellow text-6xl text-center">
            <FaFile className="inline-block" />
          </div>
          <h3>Aiemmat luentomateriaalit</h3>
          <p className="text-sm">
            Listaukset aiempien vuosien luentomateriaaleista
          </p>
          <p>
            <Link
              className=" button button-primary inline-block"
              to="/aiemmat-luentomateriaalit"
            >
              Katso
            </Link>
          </p>
        </div>
      </div>
      <div className="text-center md:w-4/12">
        <div className="icon-box ibox-center ibox-secondary ibox-outline ibox-effect ibox-plain">
          <div className="ibox-icon text-yellow text-6xl ">
            <FaFolderOpen className="inline-block" />
          </div>
          <h3>Tilastot</h3>
          <p className="text-sm">
            Hautaustoiminnan ja krematoriotoiminnan tilastoja
          </p>
          <p>
            <Link
              className=" button button-primary inline-block"
              to="/tilastot"
            >
              Katso
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
